
.contents-citations{
    /* display: none; */
    /* background-color: #fb9101; */
    width: inherit;
    display: none; 
    justify-content: flex-end;
    
}

.citations{
    display: block;
    border-left: 5px solid rgb(209, 122, 0);
    margin:3px;
    padding: 5px;
    padding-bottom: 0px;
    overflow: scroll;
    font-family: Consolas,monospace;
    font-size: 0.8em;
}

.button-citations ,.button-copy{
    border: none;
    background-color: transparent;
    font-size: 1em;
    color: rgb(209, 122, 0);
    
}

.button-citations{
    text-decoration: underline;
    border-radius: 2px;
    cursor:pointer;
    font-family: Cambria;
    font-size:large;
    width: auto;
    padding: 0px;
}

.button-copy{
    color: rgb(209, 122, 0);
    font-style: italic;
    vertical-align:top;
    transform:rotate(7deg);
    -ms-transform:rotate(-90deg); 
    -moz-transform:rotate(-90deg); 
    -webkit-transform:rotate(-90deg); 
    -o-transform:rotate(-90deg);

    display: inline-flex;
    flex-direction: column;
    align-self:flex-end;
    
    cursor:pointer;
    min-width: 100px; 
    position:relative;

    margin-left:-45px;
    margin-right:-40px;
    margin-bottom: 40px;
    white-space: nowrap;
    text-align: center;
    font-family: Cambria;

}

th, td {
    padding-left: 15px;
    padding-right: 15px;
    text-align: left;
    vertical-align: top;
}

a:link, a:visited {
    color: black;
}
