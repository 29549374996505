
@font-face{
    font-family: YouthTouch;
    src: url('../fonts/YouthTouch.ttf');

}
@font-face{
    font-family: IndulgeScript;
    src: url('../fonts/IndulgeScript.otf');
}
@font-face{
    font-family: CambriaN;
    src: url('../fonts/Cambria.ttf');
}

@font-face{
    font-family: Noteworthy;
    src: url('../fonts/Noteworthy.ttf');
}


.body{
    /* width: inherit; */
    /* min-width: 100%; */
    /* max-width: 100%;  */
    /* 1425px; */
    background-color: white; 

    /* -webkit-touch-callout: none; /* iOS Safari 
    -webkit-user-select: none; /* Safari 
     -khtml-user-select: none; /* Konqueror HTML 
       -moz-user-select: none; /* Firefox 
        -ms-user-select: none; /* Internet Explorer/Edge 
            user-select: none;   
            */
}

.head-div{
    display: inline-block;
    background-color:#e8e8e6;
    width: 98%;
    align-items: center;
    position: relative;
    border-radius: 25px;
    padding: 1%;
}

.body-div{
    margin-top: -0.1%;
    width: 100%;
    background-color: #e8e8e6;
    border-radius: 25px;
    display: inline-block;
} 

  
.margin-line {
    text-align:left;
    border: 3px
    solid  #fb9101;
    border-radius: 25px;
    width: 90%;
}

#d{
    margin-top: 0%;
}
#u{
    margin-top: 0.1%;
    margin-bottom: 0%;
} 

.typewriter h1{
    overflow: hidden;
    border-right: 0.1em solid #fb9101 ;
    white-space: nowrap;
    animation: 
        typing 2s steps(20, end) forwards,
        blinking 1.2s infinite;
    font-size: 800%;
    width: 0;
    color:#fb9101;
    letter-spacing: .15em;
    font-family: indulgeScript;
    margin: 0 auto;
  }
  
  @keyframes typing {
    from { width: 0 ;}
    to { width: 40% ;}
  }
  @keyframes blinking {
    from { border-color: transparent; }
    to { border-color:#fb9101}
  }



.menu-bar{
    margin-top: 2%;
    padding: 2%;
    background-color: #fb9101;
    align-items: center;
    border-radius: 25px;
    float: left;
}

.nav-bar-ul{
    padding: 0;
    overflow: hidden;
    font-size: 1em;
    line-height:0%
    
}

.menu-title{
    font-size: 1.5em;
    font-family: CambriaN;
    color: white;
    background-color: #fb9101;
    border-radius: 10px;
    text-align: center;
}

.nav-bar-items{
    text-decoration: none;  
    font-family: IndulgeScript;
    font-size: 1em;
} 

.nav-bar-items-div{
    display: flex;
}

.nav-bar-brack{
    color: #fb9101;
}

.nav-bar-span-l{
    width:20px;
    text-align:left;
  }

.nav-bar-span-r{
    width:20px;
    text-align:right;
}

.hyperlink{
    text-decoration: none;
    color: black;
}
.hyperlink:hover .nav-bar-brack {
    color: #e8e8e6;
}

.hyperlink:hover {
    color: #e8e8e6;
}

.hyperlink:active{
    color: #e8e8e6;
}

.footer{
    margin-top: 0.5%;
    background-color: #fb9101;
    height: auto;
    /* width: inherit; */
    padding-left: 2%;
    padding-top: 0.3%;
    padding-bottom: 0.3%;
    align-items: center;
    border-radius: 10px;
    color: white;
    font-family: CambriaN;
    /* min-width: 1425px; */
    min-height: auto;

}

.content{
    width: 50%;
    background-color: transparent;
    float: left;
    margin-left: 2%;
    /* height: 500%; */
}

.content-h1{
    background-color: transparent;
    font-family: YouthTouch;
    font-size: 2.5em;
    color: #fb9101;
    margin-bottom:-40px;
    margin-top:-30px;
    letter-spacing: 0.125em;
}

.content-h2{
    background-color: transparent;
    padding-left: 5%;
    font-family: Noteworthy;
    font-size: 1.5em;
    font-weight: 100;
    color: #fb9101;
    /* margin-bottom:-20px; */
}

.content-h4{
    font-size: 1.1em;
    font-family: Noteworthy;
    font-style: normal;
    /* background-color: purple; */
}


.content-p{
    background-color: transparent;
    font-family: CambriaN;
    line-height: 150%;
    text-align: justify;
    text-justify: inter-word;
    font-size:large;
    /* word-break: break-all; */
    white-space: normal;
}

.content-img-div{
    align-items: center;
}

.content-img{
    border-radius: 5px;
    width : 100%; 
    margin-bottom: 1%;
}

.content-img-cap{
    font-family: CambriaN;
    font-style: italic;
    text-align: center;
    color: rgb(95, 95, 95);
}

#Edu-Img-3{
    width: 50%;
    margin-left: 25%;
}

.bio-bullet{
    font-size: x-large;
    font-weight: bold;
    padding: 0.5%;
    color: #fb9101;
}
.bio-l{
    font-size: x-large;
    font-weight: bold;
}
.bio-r{
    font-size: medium;
    font-style: italic;
    padding-left: 0%;
}

.bio-body{
    font-family: CambriaN;
    display: flex;
    flex-direction: column;
    column-gap: 10%;
    margin-bottom: 2%;
}

.bio-div{
    margin-top: 2%;
    margin-bottom: 2%;
}


